@use 'sass:math';
@import 'fonts';
@import 'variables';
@import 'colors';
@import 'mixins';

@import 'swiper/scss';
// @import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

:root {
  --swiper-theme-color: $black;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bullet-width: 12px;
}
.swiper{overflow: visible;}

.icon {display: inline-block; width: 1.5em; height: 1.4em; stroke-width: 0; stroke: currentColor; fill: currentColor;}
.icon-xl {width: 1.8em; height: 1.8em;}

/* Reset */
*{box-sizing: border-box;}
html, body {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  color: $primary-font;
  display: flex;
  flex-direction: column;
  font-family:"futura-pt",sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  background: $background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.disabled{overflow:hidden}
body.disabled-modal{overflow: hidden;}
body.configurator-disabled{overflow: hidden;}
body.configurator-disabled .master-header,
body.configurator-disabled .master-header.sticky{display: none !important}
body.configurator-disabled .configurator{z-index: 110}
a:not([href]){
    cursor: pointer; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    user-select: none
}

.loader {width: 50%; height: 6px; max-width: 80px; display: block; position: absolute; z-index: 5;}
.loader::before,
.loader::after {content: '';	box-sizing: border-box;	position: absolute;}
.loader::before {	height: 100%; width: 100%; background-color: $primary; animation: loader 0.8s cubic-bezier(0, 0, 0.03, 0.9) infinite;}
@keyframes loader {
	0%, 44%, 88.1%, 100% {transform-origin: left;}
	0%, 100%, 88% {transform: scaleX(0);}
	44.1%, 88% {transform-origin: right;}
	33%, 44% {transform: scaleX(1);}
}

// .loader-circle,
// .loader-circle:after {border-radius: 50%; width: 4em; height: 4em;}
// .loader-circle {font-size: 10px; position: relative; text-indent: -9999em;
//   border-top: 0.6em solid rgba(255, 255, 255, 0.4); border-right: 0.6em solid rgba(255, 255, 255, 0.4); border-bottom: 0.6em solid rgba(255, 255, 255, 0.4); border-left: 0.6em solid $green;
//   -webkit-transform: translateZ(0); -ms-transform: translateZ(0); transform: translateZ(0); -webkit-animation: load8 1.1s infinite linear; animation: load8 1.1s infinite linear;
// }
// @-webkit-keyframes load8 {
//   0% {-webkit-transform: rotate(0deg); transform: rotate(0deg);}
//   100% {-webkit-transform: rotate(360deg); transform: rotate(360deg);}
// }
// @keyframes load8 {
//   0% {-webkit-transform: rotate(0deg); transform: rotate(0deg);}
//   100% {-webkit-transform: rotate(360deg); transform: rotate(360deg);}
// }


photo-dialog{position: fixed; top: 0; left: 0; width: 100%; height: 100%; display:  flex; align-items: center; justify-content: center; z-index: 99999; background: $light-grey;}
photo-dialog .photo-dialog-navigation{position: fixed; bottom:0; left:0; width: 100%; height:60px; padding:10px; background: $white; z-index: 5; border-top: 1px solid $grey;}
photo-dialog .photo-dialog-body{background: $light-grey; width:100%; height:calc(100% - 60px); z-index: 5; @include transition(opacity .5s $global-ease); opacity: 0; overflow-x: scroll; overflow-y: hidden; scroll-snap-type: x mandatory;}
photo-dialog .photo-dialog-body img{height: 100%; scroll-snap-align: center;}
photo-dialog.loaded .photo-dialog-body{opacity: 1;}


photo-dialog .slider-close{display: inline-block; width: 40px;height: 40px; background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0idy02IGgtNiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTYgMThMMTggNk02IDZsMTIgMTIiPjwvcGF0aD48L3N2Zz4=); background-repeat: no-repeat; background-size: 31px; background-position: center;}
photo-dialog .slider-arrow-prev, photo-dialog .slider-arrow-next{position: relative; top:auto; right: auto; left: auto; opacity:1; margin: 0; border:none}

.slider-arrows{z-index: 8;}
.swiper-button-prev,
.swiper-button-next,
.slider-arrow{@include transition(opacity .3s $global-ease, transform .3s $global-ease); opacity:0; width: 40px;height: 40px; border: 1px solid $border-default; background-color: $white;background-repeat: no-repeat;background-size: 31px;background-position: center;position: absolute;top: 50%;margin-top: -20px;z-index: 10;cursor: pointer; border-radius: $rounded-corners-buttons;}
.swiper-button-prev,
.slider-arrow-prev{left: 5px; transform: translate3d(-100%, 0, 0); background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMTUuNDEgMTYuNTlMMTAuODMgMTJsNC41OC00LjU5TDE0IDZsLTYgNiA2IDYgMS40MS0xLjQxeiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMFYweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==)}
.swiper-button-next,
.slider-arrow-next{right: 5px; transform: translate3d(100%, 0, 0); background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNOC41OSAxNi41OUwxMy4xNyAxMiA4LjU5IDcuNDEgMTAgNmw2IDYtNiA2LTEuNDEtMS40MXoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=)}

.swiper-button-prev{opacity: 1; left: 20px;}
.swiper-button-next{opacity: 1; right: 20px;}
.swiper-button-disabled{opacity:0.25;}
.swiper-slide{height: auto}

.swiper-pagination-bullet.swiper-pagination-bullet-active{background-color:$white; opacity: 1;}

router-outlet{display: none;}

p.infobox{display: inline-block; width:100%; margin: 10px 0 !important; padding: 10px !important; font-size: 14px; font-weight: 700 !important; line-height: 22px; border: 1px dashed $primary-font !important; text-align: center; color: $primary-font; @include border-radius($rounded-corners);}
p.infobox.error{color: $red; border-color:$red !important; border: 1px dashed $red !important;}
p.infobox.warning{color: $orange; border-color:$orange !important; border: 1px dashed $orange !important;}
p.promo i{font-size: 70%; font-weight: 400; opacity: 0.7; color:$primary-font !important}

ul{ padding:0; margin: 0; list-style-type: none;}

.mat-snack-bar-container {font-weight: 400}
.mat-snack-bar-container.success{background:$green; color:$white;}
.mat-snack-bar-container.info{background:$black; color:$white;}
.mat-snack-bar-container.error{background:$red; color:$white;}
.mat-snack-bar-container .mat-button{color:$white; background: none}

.text-right{text-align: right;}
.text-left{text-align: left;}
.text-center{text-align: center;}
.w-100{width: 100%;}
.hidex{overflow: hidden;}
.box{background: $white; width: 100%; padding: 16px; @include border-radius($rounded-corners);}
.gutter{padding-left: $primary-gutter; padding-right:  $primary-gutter;}
.gutter-v{padding-top: $primary-gutter-v; padding-bottom:$primary-gutter-v}
.gutter-v-half{padding-top: $primary-gutter-v-half; padding-bottom:$primary-gutter-v-half}
.gutter-t{padding-top: $primary-gutter-v}
.gutter-b{padding-bottom: $primary-gutter-v}
.resetlineheight{line-height: 0;}
hr{border-color: $border-default; border-style: solid; border-width: 1px 0 0 0; width: 100%;}

:focus{outline: none}

a{color:$primary; text-decoration: none; cursor: pointer;}

a.back{margin-bottom: 50px; padding:4px 0 5px 40px; height:30px; color:$white; position: relative; @include transition(transform .3s $global-ease);}
a.back span{width: 30px; height: 30px; background: rgba(0,0,0,0.1); position: absolute; top:0; left:0;}
a.back span::after{position: absolute; top:7px; left:10px; width: 0; height: 0; border-style: solid; border-width: 8px 8px 8px 0; border-color: transparent #ffffff transparent transparent; content:''}
.red{color:$red} 
.label{text-transform: uppercase; font-size: 11px; font-weight: 700; padding: 5px 10px; text-transform: uppercase; @include border-radius(30px); position: relative;}
.label.blue{background:$blue; color:$white} 
.label.green{background:$green; color:$white} 
.label.amber{background:$amber; color:$white} 
.label.teal{background:$teal; color:$white} 
.label.red{background:$red; color:$white} 
.label.lime{background:$lime; color:$white} 
.label.deep-purple{background:$deep-purple; color:$white} 
.label.cyan{background:$cyan; color:$white} 
.label.deep-pink{background:$deep-pink; color:$white} 
.label.light-green{background:$light-green; color:$white} 
.label.orange{background:$orange; color:$white} 
.label.brown{background:$brown; color:$white} 
.label.blue-grey{background:$blue-grey; color:$white} 
.label.accent{background:$accent; color:$white}
.label.accent-break{background:$accent-break; color:$white}

.label span.confirmed,
.label span.unconfirmed{position: absolute; top: 50%; right:4px; width:16px; height: 16px; border-radius: 8px; margin-top:-8px; background-color: $red;}
.label span.confirmed {background-color: $green;}

.icon-phone.available{color:$green;}

.square-wrap{display: block; width: 100%; padding-bottom: 100%; position: relative;}
.horizontal-wrap{display: block; width: 100%; padding-bottom: 50%; position: relative;}
.horizontal-wrap-third{display: block; width: 100%; padding-bottom: 33.333%; position: relative;}
.horizontal-wrap-half{display: block; width: 100%; padding-bottom: 25%; position: relative;}
.wrap-content{position: absolute; top:0; left:0; width:100%; height:100%}
.wrap-content.bottom{top:auto; bottom:0; height: auto;}
/*flx*/

.mr-half{margin-right: 8px;}
.mb-half{margin-bottom: 8px;}
.mb-default{margin-bottom: 16px;}

.hide{display: none !important;}
.hide-xs{display: none;}
.hide-sm{display: none;}

.flx-row{display: flex;}
.flx-grw{flex: 1 1 auto;}

.flx-col{display: flex; flex-direction: column;}

.flx-col.flx-gap-half > *,
// .flx-row.flx-gap-half > *
.flx-dynamic.flx-gap-half > *{margin-bottom: 8px;}
.flx-col.flx-gap-default > *,
// .flx-row.flx-gap-default > *,
.flx-dynamic.flx-gap-default > *{margin-bottom: 16px;}
.flx-col.flx-gap-double > *,
// .flx-row.flx-gap-double > *,
.flx-dynamic.flx-gap-double > *{margin-bottom: 32px;}
.flx-col.flx-gap-triple > *,
// .flx-row.flx-gap-triple > *,
.flx-dynamic.flx-gap-triple > *{margin-bottom: 48px;}
.flx-col.flx-gap-quadruple > *,
// .flx-row.flx-gap-quadruple > *,
.flx-dynamic.flx-gap-quadruple > *{margin-bottom: 64px;}

.flx-col.flx-gap-half > *:last-child,
.flx-col.flx-gap-default > *:last-child,
.flx-col.flx-gap-double > *:last-child,
.flx-col.flx-gap-triple > *:last-child,
.flx-col.flx-gap-quadruple > *:last-child{margin-bottom: 0;}

.flx-row.flx-gap-half-forced > *,
.flx-dynamic.flx-gap-half-forced > *{margin-right: 8px; margin-bottom: 0;}	
.flx-row.flx-gap-default-forced > *,
.flx-dynamic.flx-gap-default-forced > *{margin-right: 16px; margin-bottom: 0;}	

.flx-row.flx-gap-half-forced > *:last-child,
.flx-row.flx-gap-default-forced > *:last-child,
.flx-dynamic.flx-gap-half-forced > *:last-child,
.flx-dynamic.flx-gap-default-forced > *:last-child,
.flx-col.flx-gap-half-forced > *:last-child,
.flx-col.flx-gap-default-forced > *:last-child{margin-right: 0;}

.flx-dynamic{display: flex; flex-direction: column;}

.flx-dynamic.flx-gap-half > *:last-child,
.flx-dynamic.flx-gap-default > *:last-child,
.flx-dynamic.flx-gap-triple > *:last-child,
.flx-dynamic.flx-gap-quadruple > *:last-child{margin-bottom: 0;}

.flx-cntr-strt{justify-content: center; align-items: flex-start;}
.flx-cntr-cntr{justify-content: center; align-items: center;}
.flx-cntr-end{justify-content: center; align-items: flex-end;}

.flx-btwn-strt{justify-content: space-between; align-items: flex-start;}
.flx-btwn-cntr{justify-content: space-between; align-items: center}
.flx-btwn-end{justify-content: space-between; align-items: flex-end;}

.flx-strt-strt{justify-content: flex-start; align-items: flex-start;}
.flx-strt-cntr{justify-content: flex-start; align-items: center;}
.flx-strt-end{justify-content: flex-start; align-items: flex-end;}

.flx-end-strt{justify-content: flex-end; align-items: flex-start;}
.flx-end-cntr{justify-content: flex-end; align-items: center;}
.flx-end-end{justify-content: flex-end; align-items: flex-end;}

.flx-dynamic-33{width: 100%}
.flx-dynamic-50{width: 100%;}

.flx-wrap{flex-wrap: wrap;}
.flx-wrap-grid{flex-wrap: wrap; margin: -8px}
.flx-wrap-grid > * {padding: 8px; width: 100%;}
.flx-wrap-grid.flx-wrap-grid-2-xs > *,
.flx-wrap-grid.flx-wrap-grid-2 > * {width:50%;}
.flx-wrap-grid.flx-wrap-grid-3-xs > *,
.flx-wrap-grid.flx-wrap-grid-3 > * {width:33.333%;}
.flx-wrap-grid.flx-wrap-grid-4-xs > *,
.flx-wrap-grid.flx-wrap-grid-4 > * {width: 25%;}
.flx-wrap-grid.flx-wrap-grid-5-xs > *,
.flx-wrap-grid.flx-wrap-grid-5 > * {width: 20%;}
/*flx*/

/*btns*/


.icon-link{display: flex; align-items: center; line-height: 1.2rem;}
.icon-link span{ position: relative;}
.icon-link svg{margin-right: 8px}
a.link{position: relative; color: $primary-font;}
a.icon-link span::before,
a.link::before{position:absolute; bottom:0; left: 0; width: 0; height: 1px; background-color: $primary-font; content: ''; @include transition(width .5s $global-ease);}
a.icon-link:hover span::before,
a.link:hover::before{width: 100%}

.icon-link.primary{color:$primary-font;}
.icon-link.primary span::before{background-color:$primary-font;}
.icon-link.accent{color:$white;}
.icon-link.accent span::before{background-color:$white;}

.flat-button,
.stroked-button,
button{font-family:"futura-pt",sans-serif; font-weight: 700; position: relative; box-sizing: border-box; color:$primary-font; line-height: 39px; padding-top: 1px; background: none; display: inline-block; white-space: nowrap; text-align: center; text-decoration: none; user-select: none; cursor: pointer; outline: none; border: 1px solid $white; padding-left: 16px; padding-right: 16px; 
@include transition(color .5s $global-ease); @include border-radius($rounded-corners-buttons);}
button.icon-button{padding: 0; min-width: 0; width: 40px; height: 40px; flex-shrink: 0; line-height: 40px;}
button svg{vertical-align: middle}

.stroked-button,
a.stroked-button{border: 1px solid $border-default; @include transition(border-color .5s $global-ease);}

.stroked-button.warn,
a.stroked-button.warn{border-color: $light-red; color: $red}

.flat-button:disabled,
.stroked-button:disabled {opacity: 0.5;}

.flat-button{min-height: 40px; padding-top: 1px; line-height: 39px; z-index: 2; border: 1px solid $white}
.flat-button.fat{min-height: 50px; font-weight: 700; line-height: 49px;}
.flat-button.primary{background-color:$black; border-color: $black; color:$white}
.flat-button.accent{background-color:$white; border-color: $white; color:$black}
.flat-button.warn{background-color:$red; border-color: $red; color:$white}
.flat-button::after{content:''; position: absolute; top: 0; left: 0; bottom: 0; right:100%; z-index: -1; @include border-radius(math.div($rounded-corners-buttons, 2)); @include transition(right .5s $global-ease);}
.flat-button.fat::after{@include border-radius(math.div($rounded-corners-buttons, 2));}
.flat-button.primary::after{ background-color: $white; color:$black}
.flat-button.accent::after{ background-color: $black; color:$white}
.flat-button.warn::after{ background-color: $white; color:$red}
.flat-button.basic::after{ background-color: $white; color:$red}
.flat-button .button-wrapper{position: relative; z-index: 1;}

.flat-button.primary:hover{color: $black;}
.flat-button.primary:hover::after{right: 0}
.flat-button.accent:hover{color: $white;}
.flat-button.accent:hover::after{right: 0}
.flat-button.warn:hover{color: $red;}
.flat-button.warn:hover::after{right: 0}
.flat-button.basic:hover{color: $black;}
.flat-button.basic:hover::after{right: 0}

.flat-button:disabled {opacity: 0.3;}

.stroked-button:hover{border-color:$black}
.stroked-button.warn:hover{border-color:$red}

.flat-button.gotop{position: fixed; left:11px; bottom: 11px; padding:0; width:44px; z-index: 1002;}

.master-router{min-height: calc(100vh - 160px);}

/*sidenav*/
// side-nav{position:fixed;z-index:9999;}
side-nav .side-nav-content{position:fixed;z-index:9999;top:0;height:100vh;width:50vw;overflow-y:scroll; background-color: $grey;}
side-nav.left .side-nav-content{left:-100%; @include transition(left .4s $global-ease);}
side-nav.right .side-nav-content{right:-100%; @include transition(right .4s $global-ease);}
side-nav.left.open .side-nav-content{left:0; pointer-events:auto}
side-nav.right.open .side-nav-content{right:0; pointer-events:auto}
.screen-overlay{position: fixed;z-index:9999; top: 0; left: 0; width: 100vw; height: 100vh; background-color: #0009; visibility: hidden; opacity:0; @include transition(opacity .4s $global-ease);}
side-nav.open .screen-overlay{visibility:visible; opacity: 1;}
.cart_items{position: absolute; top: -7px; right: -7px; background:$black; color:$white; border-radius: 11px; width:22px; height: 22px; font-size: 11px; line-height: 22px; font-weight: 700; z-index: 2;}

.master-header-info{position: relative; z-index: 607; height: 40px; width: 100%; border-top: 1px solid $primary; border-bottom: 1px solid $border-default; text-transform: uppercase; font-size: 0.8rem; background-color: $white;}
.master-header-info .info-right a{color:$primary-font; margin-right: 8px;}
.master-header-info p{margin:0 0 0 8px; font-weight: 700; font-size: 0.8rem;}
.master-header-info .mat-icon-button{margin-top: -3px; font-size: 0.8rem;}
.master-header-info .info-left .icon-link svg{margin-right: 8px;}
.master-header-info .info-right .icon-link svg{margin-left: 8px;}

.top-list-wrap{position: relative;}
.top-list-wrap button{font-weight: 700; height: 28px; line-height: 24px; border-radius: 0; padding:0 0 0 6px; border:1px solid transparent}
.top-list-wrap.visible button{border:1px solid $border-default; border-bottom:1px solid $white; background: $white; position: relative; z-index: 607;}
.top-list-wrap .top-list{position: absolute; top:27px; right:0; min-width: 70px; border:1px solid $border-default; display: flex; flex-direction: column; z-index: 606; background-color: $white; text-align: right;}
.top-list-wrap .top-list a{line-height: 16px; text-align: right; padding:6px 12px; white-space: nowrap; width: 100%; border-radius: 0; border: none; font-weight: 700; font-size: 90%;}

.sticky{position: -webkit-sticky; position: sticky; top: 0;}

.master-header{ width: 100%; z-index: 606; padding:0; border-bottom: 1px solid $border-default; background-color: $white;}
.master-header-wrapper{height:68px;}
.master-header-top{width: 100%; height: 60px; position: relative; z-index: 104;}
.master-header-right{flex:1;}
.master-logo{position: absolute; top: 15px; left: $primary-gutter; width: 127px; height: 40px; margin: 0; padding-top:0; line-height:16px; text-indent: -999px; display: inline-block; overflow:hidden; background: url(/assets/logo.svg) center no-repeat;}
.master-logo span{font-size: 12px}
.master-header #basket .mat-badge-content{right: -7px; top:-7px; position: relative; z-index: 2;}
.master-header #basket svg{position: relative; z-index: 2;}

.page-normal{background:$white;}

.search {position: absolute; left: 50%; transform: translateX(-50%); display: flex; border: 1px solid $border-default; background: $light-grey; min-width:40vw; z-index: 999;}
.search.hidden{display: none;}
.search.active,
.search:focus-within{border-color:$primary; box-shadow: 0 6px 18px rgb(0, 0, 0, 0.2);}
.search input{border:none; padding:0 10px; background: $light-grey; flex: 1}
.search .icon-button{margin:2.5px; color:$primary}
.search-results{position: absolute; top: 100%; left: -1px; right: -1px; overflow:hidden; padding: 0; border: 1px solid $primary; background:$white; box-shadow: 0 6px 18px rgb(0, 0, 0, 0.2);}
.search-results a{background:$white; padding: 4px 16px; border-radius: $rounded-corners; position: relative; overflow: hidden; color:$black; font-weight: 700}
.search-results a div span{font-weight: 400; opacity: 0.8;}
.search-results a app-price{margin-left: auto; margin-right: 8px;}
.search-results a::ng-deep .price{padding:5px 0 0 0; @include transition(color 1s $global-ease); text-align: left;}
.search-results hr{ width: calc(100% - 16px); height: 1px; background: $border-default;}
.search-results .mat-button-base{margin: 0 8px 8px 8px;}
.search-results-backdrop{position: fixed; top:0; left: 0; width: 100%; height: 100%; z-index: 10;}

.lang {width: 26px; height: 26px; background: $red;}
.navigation{position: relative; width: 100%; background-color: $white;}
.navigation > ul{z-index: 105; position: relative; background: $white;border-bottom: 1px solid $border-default}
.navigation li a,
.navigation li button{position: relative; display: inline-block; cursor: pointer; color: $primary-font; height: 50px; padding: 0 30px; margin: 0; text-transform: uppercase; font-size: 13px; font-weight: 700; line-height: 50px; white-space: nowrap; border: none; background: none;}
.navigation li a::before,
.navigation li button::before{position: absolute; width: 0; height: 1px; background: $black; content:''; left: 50%; bottom:-1px; opacity:0; transform: translateX(-50%); @include transition(width .4s $global-ease, opacity .4s $global-ease);}
.navigation li a.active::before,
.navigation li a.hovered::before,
.navigation li a:hover::before,
.navigation li button.active::before,
.navigation li button.hovered::before,
.navigation li button:hover::before{width:100%; opacity:1}
// 
.master-header-drop{position: absolute; top: 100%; width: 100%; left: 0; background: $white; padding: 0; overflow: hidden; z-index: 103; transform: translate3d(0, -150%, 0); @include transition(transform .5s $global-ease);}
.master-header-drop.visible{transform: translate3d(0, 0, 0); visibility: visible;}
.master-header-drop ul{list-style-type: none; padding:0; margin:0; width: 100%;}
.master-header-drop ul ul {width: 100%; z-index:2}
.master-header-drop ul ul ul{z-index:3}
.master-header-drop ul ul ul ul{z-index:4}
.master-header-drop ul li {width: 100%; margin: 0; padding: 0;}
.master-header-drop ul li button,
.master-header-drop ul li a{display: inline-block; text-align: left; text-transform: none; cursor: pointer; color: $black; height: 50px; line-height: 50px; font-size: 13px; font-weight: 700; padding:0 16px; margin: 0; width: 100%; white-space: nowrap;}
.master-header-drop ul li a::before{left: 0; transform: translateX(0%);}
.master-header-drop ul li a.active::before,
.master-header-drop ul li a.hovered::before,
.master-header-drop ul li a:hover::before{width:100%; opacity:1;}


.master-header-drop .master-header-drop-main{background: $grey; border-right: 1px solid $border-default; width: 80%;}
.master-header-drop .master-header-drop-main.multilevel{width:20%}
.master-header-drop .master-header-drop-main li{border-bottom: 1px solid $border-default;}
.master-header-drop .master-header-drop-main li.active{background: $white; box-shadow: 1px 0px white}

.master-header-drop .master-header-drop-secondary{padding:0 16px; width:60%}
.master-header-drop .master-header-drop-secondary button{font-size: 12px; opacity: 0.7}
.master-header-drop .master-header-drop-secondary li{max-width: 260px;}
.master-header-drop .master-header-drop-secondary li a{line-height: 20px; height:20px; margin:4px 16px; padding: 0; width: auto;}

.master-header-drop .navigation-image-wrap{padding:16px; width: 20%;}
// .navigation-dropdown{padding:20px 0; width: 100%;}
// .navigation-dropdown > li{border-right: 1px solid $border-default; max-width: 220px !important; padding:0 8px}
// .navigation-dropdown > li:last-child{border-right: none}
// .navigation-dropdown li a::before{background: $primary; height: 1px;} 
// .navigation-dropdown li a{font-weight: 700; color: $primary-font; height: auto; line-height: 20px; font-size: 90%; width: 100%; padding: 0 4px; display: flex; flex-direction: column; align-items: flex-start; justify-content: center; white-space: normal;}
// .navigation-dropdown li li a{font-size: 70%; opacity: 0.9;}
// .navigation-dropdown .nav-img-wrap {width: 100%; max-width: 120px; position: relative; display: inline-block;}
// .navigation-dropdown .nav-img-wrap::before{content:''; display:inline-block; width:100%; padding-bottom: 100%;}
// .navigation-dropdown .nav-img-wrap img{object-fit: contain;}
// .master-header-drop .collection {background: $light-grey; width: 20%; margin: 20px 0}

.breadcrumb-wrap{ width: 100%; height: 40px; overflow: hidden; background: $light-grey}
.breadcrumb{list-style-type: none; padding:0; margin:0; height: 40px; line-height: 40px; white-space:nowrap; overflow:hidden; -webkit-overflow-scrolling: touch;}
.breadcrumb li {display: inline-block; margin: 0 6px 0 0}
.breadcrumb li span,
.breadcrumb li a{height: 40px; line-height: 40px; font-size:14px; color:$secondary; opacity: .6;}
.breadcrumb li svg {vertical-align: -3px; opacity: .6; font-size: 70%; margin-left: 5px;}
.breadcrumb li:last-child span,
.breadcrumb li:last-child a{color:$secondary; opacity: 1;}
.breadcrumb li:last-child svg{display: none;}

#quick-add{background:$white; padding: 15px; top: 70px; right:90px; position: fixed; width: 320px; min-height: 200px; max-width: 90vw; border-top: 3px solid $secondary; box-shadow: 0 2px 4px rgba(0,0,0,0.4); z-index: 999; @include transition(top .5s $global-ease);}
#quick-add.fixed{top: 55px;}
#quick-add::after{content: ''; position: absolute; top:-8px; right:20px; @include css-triangle($secondary, up, 8px);}
#quick-add h4{margin:0 0 10px 0;}

.box-16-9-wrap{position: relative; width: 100%;}
.box-16-9{position: relative; width: 100%; z-index: 4; line-height: 0; overflow: hidden; }
.box-16-9::after{content:''; width: 100%; padding-top: 56.25%; display: inline-block;}
.box-16-9 slider{position: absolute;}
body header{position: relative; z-index: 5; padding:16px 0; border-bottom: 1px solid $border-default; background: $white; @include border-radius($rounded-corners);}
body header h4{margin: 0; color:$primary; font-weight: 700; font-size:90%;}
body header h2{font-size:200%; margin: 0;}
body header p {padding:6px 0; margin-top: 24px; max-width: 80%; font-weight: 400; font-size: 120%}

body .fancy-box {position: relative; z-index: 5; padding-bottom: 8px; border-bottom: 1px solid $border-default}
body .fancy-box h4{margin: 0; font-weight: 400; font-size:80%;}
body .fancy-box h2{font-size:150%; margin: 0; font-weight: 700;}

.badges{z-index:10}
.badges .badge{display: inline-block; font-size: 8px; padding:2px 6px 2px 20px; margin-bottom: 2px; color: $white; min-width: 40px; height: 20px; line-height: 16px; text-align: center; font-weight: 700; @include border-radius($rounded-corners); position: relative; overflow: hidden;}
.badges .badge::after {content: ''; position: absolute; left: 5px; top: 50%; transform: translateY(-50%); border-radius: 100%; z-index: -1; width: 8px; height: 8px; box-shadow: 0 0 0 100vw rgba(0,0,0,.9);}
// .badges .badge.new{background:#c6e1f4;}
// .badges .badge.feat{background:#caecdf}
// .badges .badge.bestseller{background: #fbe0cd;}
// .badges .badge.day{background: #f9ecbe;}
.price{font-weight: 400; font-size:16px; line-height: 20px;}

.dummy-card{position: relative; width: 100%; height:0; padding-bottom: 150%; background:$white; position: relative; border: 1px solid $border-default; @include border-radius($rounded-corners);}
.low-stock{background:$black; color:$white; @include border-radius($rounded-corners); padding:4px; margin: 4px 0; text-align: center;}


app-product {display: flex; flex-direction: column; background:$grey; position: relative; padding: 0; @include border-radius($rounded-corners); min-height: 100%; border: 1px solid $dark-grey}
app-product .badges{position: absolute; top:4px; right:-6px; display: flex; flex-direction: column;}
app-product a{color:$primary-font}
app-product .product-image{position: relative; width: 100%; height:0; padding-bottom: 100%;  @include border-radius($rounded-corners); overflow: hidden;}
.slider-cell app-product picture img,
app-product .product-image img{top:5%; left:5%; width:90%; height:90%; object-fit: contain;}
app-product .card-product-content-wrap{position: relative; background-color: $light-grey; display: flex; flex-grow: 1;}
app-product .card-product-content{width: 100%; color:$secondary; padding: 16px; margin:0; display: flex; flex-direction: column; justify-content: space-between; z-index: 2; background-color: transparent; @include transition(background-color .4s $global-ease);}

app-product .card-product-actions{display: flex; flex-direction: column; padding:0 16px 16px 16px; background-color: $light-grey;}
app-product .card-product-actions > div{display: flex;}

app-product app-price{position: relative; width: 100%; flex-direction: column; align-items: flex-start; border-bottom: 1px solid $border-default; padding-bottom: 8px;}
app-product h3{font-size: 16px; line-height: 100%; font-weight: 400; text-transform: uppercase; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; margin-top: 8px; }
app-product p{font-size: 13px; font-weight: 400; opacity: 0.6; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}


app-product .favorite{position: absolute; top:4px; left:4px; z-index: 9}
app-product .favorite .checkbox{background-color: $white; width: 40px; height:40px; padding: 12px; cursor: pointer}
app-product .favorite input{background-color: $grey; width: 16px; height: 16px; border-radius: 8px; margin:0; -webkit-appearance: none; -moz-appearance: none; appearance: none;}
app-product .favorite input:checked {background-color: $background-darker;}
app-product .favorite .icon-button{background-color: $white; line-height: 0;}

.loading-content{position: relative; overflow: hidden; background-color: $grey; border-radius: 4px;}
.loading-content::after {content: ''; display: block; position: absolute; left: -33.33333%; top: 0; height: 100%; width: 33.33333%; background: linear-gradient(to right, transparent 0%, $white 50%, transparent 100%); animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;}
@keyframes load {from {left: -33.33333%;} to{left: 100%;}}

.input-quantity{background-color: $grey; border:1px solid $border-default; border-width: 1px 0; min-width:30px; width:52px; height:52px; margin: 0 -3px; flex:1 1 auto; text-align: center; font-weight: 700; vertical-align: bottom;}
.stroked-button.btn-quantity{padding:0; background:$white; border-radius: $rounded-corners-buttons; min-width:40px; width: 52px; height: 52px; flex: 0 1 auto}
.btn-delete{line-height: 54px; color:$red; padding:0; height: 52px; min-width: 52px !important; margin-left: 6px; background: $white; @include border-radius($rounded-corners-buttons);}
.btn-delete svg{vertical-align: -3px;}

/*header*/
// .header-section{padding: 16px; background: $white;}
// .header-section-img{position: relative; width: 100%; padding-bottom: 15.625%;}
// .footer-section{margin: 0; padding-top: 16px; padding-bottom: 16px;}
// .header-section h1, .footer-section h1, 
// .header-section h2, .footer-section h2,
// .header-section h3, .footer-section h3{margin: 0;}
// .header-section p{margin:0 !important;}
/*dialog without padding*/

body.disabled-modal modal-dialog{position: fixed; top:0; left: 0; width: 100%; height: 100%; z-index: 9999; display: flex; justify-content: center; align-items: flex-start; overflow-y: scroll;}
modal-dialog .dialog{background:$white;  @include border-radius($rounded-corners-buttons); box-shadow: 0 16px 32px rgba(0,0,0,0.15), 0 8px 12px rgba(0,0,0,0.10); max-width: 96vw; padding:16px; margin: auto 0; z-index: 9999; @include transition(opacity .4s $global-ease);}
modal-dialog .dialog-buttons{display: flex; justify-content: flex-end; align-items: center;}
modal-dialog .dialog-buttons > * {margin-right: 16px;}
modal-dialog .dialog-buttons > *:last-child {margin-right: 0;}


modal-dialog .snackbar{position: fixed; bottom: 16px; left: 50%; background: $background-darker; color:$white; transform: translateX(-50%); display: flex; justify-content: space-between; align-items: center; @include border-radius($rounded-corners-buttons * 2); box-shadow: 0 16px 32px rgba(0,0,0,0.15), 0 8px 12px rgba(0,0,0,0.10); min-width: 300px; max-width: 96vw; padding:8px 8px 8px 16px; z-index: 9999; @include transition(opacity .4s $global-ease);}
modal-dialog .snackbar.success{ background:$green; color:$white; }
modal-dialog .snackbar.success .flat-button::after{ background:$green; }
modal-dialog .snackbar.warning{ background:$red; color:$white; }
modal-dialog .snackbar.warning .flat-button::after{ background:$red; }
modal-dialog .snackbar .dialog-buttons{margin-left: 16px;}

modal-dialog .screen-overlay.visible,
modal-dialog .snackbar,
modal-dialog .dialog.visible{opacity: 1; visibility: visible;}

.nopad-dialog .mat-dialog-container{padding:0}
.nobg-dialog .mat-dialog-container{background: none; padding: 0; overflow: hidden; box-shadow: none}
.mat-dialog-container .dialog-close{position: absolute; top:-14px; right:-14px; border: 1px solid $border-default; background-color: $white; z-index: 9; line-height: 38px; @include border-radius($rounded-corners-buttons);}

/*master loader*/
#master-loader-bar.loader{position: fixed; top: 0; left: 0; width:100vw; max-width: 100vw; height:2px; background:$light-green; z-index: 99999999999;}


/*lists*/
.blist li{margin: 5px 0 5px 25px; display: inline-block; position: relative; width: 100%;}
.blist li::before{position: absolute; top:50%; left: -17px; transform: translateY(-50%); width:6px; height: 6px; border-radius: 3px; background: $primary; content:''}

.list-image img{height: 40px; margin:5px}

/*dynimage*/
.dummy-img {position: absolute; top:0; left:0; width: 100%; height: 100%; overflow: hidden;}
.dummy-img::after{position: absolute; top:0; left: 0; width:100%; height: 100%; content:''}
.dummy-img.natural{position: relative; display: inline-block; padding-bottom: 100%;}
.dummy-img img{position: absolute; top:0; left:0; width: 100%; height:100%; object-fit: cover;}

/*paginator*/
.paginator {flex-direction: column; padding: 8px; min-height: 60px; display: flex; align-items: center; justify-content: space-between; background: $white; border-bottom: 1px solid $border-default; @include border-radius($rounded-corners);}
.paginator:last-child{ border-top: 1px solid $border-default;}
.paginator .mat-icon-button{background:$light-grey; border-radius: 0; border-left: 1px solid $white}
.paginator .mat-paginator{background: none;}
.paginator .mat-paginator .mat-paginator-container{padding:0}
.paginator .mat-form-field-flex > .mat-form-field-infix { padding: 0.4em 0px !important;}
.paginator .mat-form-field-label-wrapper { top: -1.5em; }
.paginator .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {transform: translateY(-1.1em) scale(.75); width: 133.33333%;}
.paginator .mat-form-field-appearance-outline .mat-form-field-wrapper,
.paginator .mat-form-field-wrapper{margin:0; padding:0;}
// .paginator .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start, 
// .paginator .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end, 
// .paginator .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
// .paginator .mat-form-field-appearance-outline .mat-form-field-outline-gap,
// .paginator .mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-start, 
// .paginator .mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-end{border-width: 0;}
.paginator .mat-paginator {
  .mat-paginator-container {
      justify-content: space-between;
  }

  // override material paginator page switch
  .mat-paginator-range-label {
      order: 2;
      margin: 0 8px;
  }

  .mat-paginator-navigation-previous {
      order: 1;
  }
  .mat-paginator-navigation-next {
      order: 3;
  }
  .mat-paginator-navigation-last {
      order: 4;
  }
}

/*switch component*/
.switch,
.switch-style,
.switch-style:before{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.switch {
  display: inline-block;
  font-size: 100%;
  height: 1.6em;
  line-height: 1.6em;
  position: relative;
  .switch-style {
    height: 1.6em;
    left: 0;
    background: $grey;
    -webkit-border-radius: 0.8em;
    border-radius: 0.8em;
    display: inline-block;
    position: relative;
    top: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    width: 3em;
	vertical-align: bottom;
	margin-right: 8px;
    cursor: pointer;
    &:before{
      display: block;
      content:'';
      height: 1.4em;
      position: absolute;
      width: 1.4em;
      background-color: #fff;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      left: 0.1em;
      top: 0.1em;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
    }
  }
  &>input[type="checkbox"]{
    display:none;
    &[disabled] + .switch-style{
      cursor: not-allowed;
      background-color:$grey;
    }
    &:checked + .switch-style{
      background-color:$primary;
      &:before{
        left:50%;
      }
    }
    &:checked[disabled] + .switch-style{
      background-color: $grey;
    }
  }
  &.switch-blue>input[type="checkbox"]{
    &:checked + .switch-style{
      background-color: $primary;
    }
    &:checked[disabled] + .switch-style{
      background-color: $primary;
    }
  }
}


/*radio checkbox form field mimic*/
.mat-error{white-space: nowrap;}
.radio-checkbox-wrap{margin: .25em 0; padding-bottom:1.34375em}
.mat-error.custom,
.rodo-checkbox .mat-error,
.radio-checkbox-wrap .mat-error{padding:0 1em; font-size:75%}

.mat-option app-image{width:40px; height:40px; margin:4px 4px 4px 0; vertical-align: top; border-radius: 4px; display: inline-block;}
.mat-option app-image img{opacity: 1 !important; }
.mat-option app-price {display: inline-block;}
.mat-option app-price .price{font-weight: 400;}
.mat-select-panel{ -ms-overflow-style: none; scrollbar-width: none;}
.mat-custom-panel {max-height: 212px; overflow: auto; -ms-overflow-style: none; scrollbar-width: none; }
.mat-custom-panel .mat-form-field-infix{width: 50px;}
.mat-custom-panel .mat-option-text{display: flex; justify-content: space-between;}
.mat-custom-panel.with-price {padding: 1em 0}
.mat-custom-panel.with-price .mat-option{height:auto}
.mat-custom-panel.with-price .mat-form-field-wrapper{padding-bottom:0;}
.mat-custom-panel.with-price .mat-slide-toggle{width: 100%;}
.mat-custom-panel.with-price .mat-slide-toggle .mat-slide-toggle-label{justify-content: space-between;}
.mat-custom-footer{display: flex; height: 44px; background: $grey}
.mat-custom-footer .flat-button {padding:0; width: 50%;}
.mat-custom-footer button:first-child{background: transparent; color:$primary-font}
.mat-form-field-appearance-outline .mat-select-value{transform: translateY(-20%);}

body .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start, 
body .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end, 
body .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
body .mat-form-field-appearance-outline .mat-form-field-outline-gap,
body .mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-start, 
body .mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-end{border-radius: 0; border-color:$border-default}

.noerror .mat-form-field-wrapper{padding-bottom:0;}

.social a{font-size:16px; color:$black}
.social span{display: none}
.social .icon{width:1.5em; height:1.5em; vertical-align: middle; opacity: 0.25; @include transition(opacity .6s $global-ease);}
.social a:hover .icon{opacity: 0.5;}

body map-info-window h4{margin:0 0 12px 0; min-width: 200px;}
body map-info-window p{margin:0 0 6px 0;}
body map-info-window p a{color:$black; text-decoration: underline;}

.rodo-checkbox{margin-bottom:14px; font-size: 12px; line-height: 16px;}
.rodo-checkbox .mat-checkbox-layout .mat-checkbox-label{white-space: normal; font-size: 12px; line-height: 16px;}

app-price{display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start;}
app-price .price{font-weight: 700;}
app-price .price-old {font-size: 80%;}
app-price .price-old span.info{display: none; margin-right: 5px;}
app-price .price-old span.normal{color:$primary-font; opacity: 0.6; text-decoration: line-through;}
app-price .price-old span.discount{color:$red; margin-left: 5px;}

// .gm-ui-hover-effect{opacity:1; position:absolute; width:40px !important; height: 40px !important; border-radius: 20px; top:-20px !important;right:-20px !important;background-color:$black !important;color:$white !important;font-family:Arial,Baskerville,monospace;font-size:24px;cursor:pointer;}
// .gm-ui-hover-effect img{display: none !important;}
// .gm-ui-hover-effect:before {content: "&times;";}
// .gm-ui-hover-effect:before, .gm-ui-hover-effect:after {position: absolute; left: 19px; top: 14px; content: ' '; height: 13px; width: 2px; background-color: $white;}
// .gm-ui-hover-effect:before {transform: rotate(45deg);}
// .gm-ui-hover-effect:after {transform: rotate(-45deg);}
// .gm-style .gm-style-iw-c{overflow: visible !important; border-radius: 2px; box-shadow: 0 10px 20px rgba(0,0,0,0.3); padding:16px 24px;}
// .gm-style .gm-style-iw-c h4{font-weight: 700;}

#fb-messenger-fake-button {padding: 0 !important; border-radius: 50%; width: 50px; height: 50px; border:none; box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); cursor: pointer; transition: all 0.3s; overflow: hidden; background: transparent;}
#fb-messenger-fake-button svg {display: block;  width: 100%; height: 100%;}
#fb-messenger-fake-button.active #logo {opacity: 0;}
#fb-messenger-fake-button.active #spinner {animation: spin 3s linear infinite; transform-origin: center;}
@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

@media screen and (min-width: 960px) {

  photo-dialog .photo-dialog-body{overflow-x: hidden; overflow-y: scroll;}
  photo-dialog .photo-dialog-body img{height: auto; width: 100%}

  .w-25{width:25%;}
  .w-33{width:33%}
  .w-50{width:50%;}
  .w-77{width:77%}
  .w-100{width:100%;}
  .hide-xs{display: inline-block;}

  .flx-dynamic{flex-direction: row;}

  .flx-wrap-grid{flex-wrap: wrap; margin: -12px}
  .flx-wrap-grid > * {padding: 12px}
  .flx-wrap-grid.flx-wrap-grid-2-sm > * {width:50%}
  .flx-wrap-grid.flx-wrap-grid-3-sm > * {width:33.333%}
  .flx-wrap-grid.flx-wrap-grid-4-sm > * {width:25%;}
  .flx-wrap-grid.flx-wrap-grid-5-sm > * {width:20%}

  .flx-cntr-strt-md{justify-content: center; align-items: flex-start;}
  .flx-cntr-cntr-md{justify-content: center; align-items: center;}
  .flx-cntr-end-md{justify-content: center; align-items: flex-end;}

  .flx-btwn-strt-md{justify-content: space-between; align-items: flex-start;}
  .flx-btwn-cntr-md{justify-content: space-between; align-items: center}
  .flx-btwn-end-md{justify-content: space-between; align-items: flex-end;}

  .flx-strt-strt-md{justify-content: flex-start; align-items: flex-start;}
  .flx-strt-cntr-md{justify-content: flex-start; align-items: center;}
  .flx-strt-end-md{justify-content: flex-start; align-items: flex-end;}

  .flx-end-strt-md{justify-content: flex-end; align-items: flex-start;}
  .flx-end-cntr-md{justify-content: flex-end; align-items: center;}
  .flx-end-end-md{justify-content: flex-end; align-items: flex-end;}

  .flx-row.flx-gap-half > *,
  .flx-dynamic.flx-gap-half > *{margin-right: 8px; margin-bottom: 0;}	
  .flx-row.flx-gap-default > *,
	.flx-dynamic.flx-gap-default > *{margin-right: 16px; margin-bottom: 0;}	
  .flx-row.flx-gap-double > *,
	.flx-dynamic.flx-gap-double > *{margin-right: 32px; margin-bottom: 0;}
  .flx-row.flx-gap-triple > *,
	.flx-dynamic.flx-gap-triple > *{margin-right: 48px; margin-bottom: 0;}
  .flx-row.flx-gap-quadruple > *,
	.flx-dynamic.flx-gap-quadruple > *{margin-right: 64px; margin-bottom: 0;}

	.flx-row.flx-gap-half > *:last-child,
  .flx-dynamic.flx-gap-half > *:last-child,
	.flx-row.flx-gap-default > *:last-child,
  .flx-dynamic.flx-gap-default > *:last-child,
	.flx-row.flx-gap-double > *:last-child,
	.flx-dynamic.flx-gap-double > *:last-child,
	.flx-row.flx-gap-triple > *:last-child,
  .flx-dynamic.flx-gap-triple > *:last-child,
  .flx-row.flx-gap-quadruple > *:last-child,
  .flx-dynamic.flx-gap-quadruple > *:last-child{margin-right: 0; margin-bottom: 0;}

	// .flx-wrap-grid .flx-dynamic-50{width: calc(50% - 8px); margin-bottom: 16px;}
	// .flx-wrap-grid .flx-dynamic-50:nth-child(even){margin-right: 0;}

	.flx-dynamic-33{width: 33.333%}
	.flx-dynamic-50{width: 50%;}


  app-price .price-old span.info{display: inline-block; color:$primary-font; opacity: 0.6;}

  app-product .card-product-actions{flex-direction: row;}
  app-product .card-product-actions .flat-button{flex: 1 0 auto; margin-left: 6px;}
  app-product:hover{z-index: 60;}
  // app-product:hover h3,
  // app-product:hover p{white-space: normal;}
  app-product:before{content:''; position: absolute; top:0; left:0; right: 0; bottom:0; z-index:-1;  @include border-radius($rounded-corners); box-shadow: 0 16px 32px rgba(0,0,0,0.15), 0 8px 12px rgba(0,0,0,0.10); opacity:0; @include transition(opacity .6s $global-ease)}
  app-product:hover::before{opacity:1;}
  app-product:hover .card-product-content{background-color: $white;}
  app-product .icon-button svg{transform: scale3d(1,1,1); @include transition(transform .2s $global-ease)}
  app-product .icon-button:hover svg{transform: scale3d(1.3,1.3,1.3);}
}

@media screen and (min-width: 1280px) {

  .hide-sm{display: inline-block;}
  .hide-md{display:none !important}

  .flx-wrap-grid{flex-wrap: wrap; margin: -16px}
  .flx-wrap-grid > * {padding: 16px}
  .flx-wrap-grid.flx-wrap-grid-2-md > * {width:50%}
  .flx-wrap-grid.flx-wrap-grid-3-md > * {width:33.333%}
  .flx-wrap-grid.flx-wrap-grid-4-md > * {width:25%;}
  .flx-wrap-grid.flx-wrap-grid-5-md > * {width:20%}

  .badges .badge{font-size: 12px; padding:2px 6px 2px 20px; margin-bottom: 4px; min-width: 60px; height: 24px; line-height: 20px;}

  .paginator{flex-direction: row;}
}


@media screen and (max-width: 1440px) {
  side-nav .side-nav-content{width:90vw;}
}

@media screen and (max-width: 1279px) {
  body .master-drawer{display:flex; min-height:100%; padding: 0; overflow: hidden; background: $background}

  .gutter{padding-left: 16px; padding-right:16px}
  .gutter-v{padding-top: 24px; padding-bottom:24px}
  .gutter-t{padding-top: 24px}
  .gutter-b{padding-bottom: 24px}


  .master-router{min-height: calc(100vh - 110px);}

  .master-logo{top: 5px; left: 16px; width: 100px; padding-top:58px; line-height:14px; font-size: 28px; font-weight: bold;}
  .master-logo span{font-size: 9px}

  .master-drawer-inner-wrap{min-height: 100%;}

  .master-header-right{margin-left: 116px}
  .master-header-right .hamburger{margin-right: auto;}

  .master-drawer-top{width: 100%; height: 60px; padding:10px; background: $white;}
  .master-drawer-bottom{width: 100%; height: 70px; padding:10px; background: $white; border-top: 1px solid $border-default; margin-top: auto}
  .master-drawer .mobile-navigation-wrap{position: relative; width: 100%; overflow: hidden; background: $white; @include transition(height .3s $global-ease);}
  
  .master-drawer ul{list-style-type: none; padding:0; margin:0; background: $white;}
  .master-drawer ul ul {position: absolute; top: 0; left: 0; width: 100%; z-index:2}
  .master-drawer ul ul ul{z-index:3}
  .master-drawer ul ul ul ul{z-index:4}
  .master-drawer ul li {width: 100%; margin: 0; padding: 0; border-bottom: 1px solid $border-default;}
  .master-drawer ul li span,
  .master-drawer ul li a{display: inline-block; cursor: pointer; position: relative; font-size: 13px; font-weight: 700; background: $white; color: $black; height: 49px; line-height: 49px; padding:0 24px; margin: 0; width: 100%; white-space: nowrap;}
  .master-drawer ul li span.container{font-weight: 400; padding: 0 16px}
  .master-drawer ul li span svg,
  .master-drawer ul li a svg{position: absolute; right:12px; top: 50%; transform: translateY(-50%);}
  .master-drawer ul li.navigation-head{background: $white; border-top: 1px solid $border-default; border-bottom: 1px solid $border-default; display: flex; justify-content: space-between;}
  .master-drawer ul li.navigation-head span,
  .master-drawer ul li.navigation-head a{font-weight: 400; background: $white; width: auto; text-transform: none;}
  .master-drawer ul li.navigation-head span {padding: 0 16px;}
  .master-drawer ul li.navigation-head span svg,
  .master-drawer ul li.navigation-head a svg{vertical-align: -5px}
  
  #navbar-footer{width:100%; padding: 0 $primary-gutter;}
  #navbar-footer .social{color:$black}
  #navbar-footer::ng-deep p{font-size:14px; font-weight: 400}
  #navbar-footer-bottom {margin:40px 0}
  
  body.scrollable-modal .mat-dialog-container{width:94vw;}
  body .mat-form-field-infix{width: 50px;}

  .master-cart app-price .price{text-align: left;}
  .master-cart app-price .price-old {text-align: left;}

  .search{top:100%; width: 100vw;}

}


@media screen and (max-width: 599px) {
  side-nav .side-nav-content{width:90vw;}
  #quick-add{right: 15px;}
  #quick-add::after{right: 50px;}

  .flx-break{flex-basis: 100%; height: 0;}

  .flx-col.flx-gap-half > *,
  // .flx-row.flx-gap-half > *
  .flx-dynamic.flx-gap-half > *{margin-bottom: 8px;}
  .flx-col.flx-gap-default > *,
  // .flx-row.flx-gap-default > *,
  .flx-dynamic.flx-gap-default > *{margin-bottom: 16px;}
  .flx-col.flx-gap-double > *,
  // .flx-row.flx-gap-double > *,
  .flx-dynamic.flx-gap-double > *{margin-bottom: 18px;}
  .flx-col.flx-gap-triple > *,
  // .flx-row.flx-gap-triple > *,
  .flx-dynamic.flx-gap-triple > *{margin-bottom: 24px;}
  .flx-col.flx-gap-quadruple > *,
  // .flx-row.flx-gap-quadruple > *,
  .flx-dynamic.flx-gap-quadruple > *{margin-bottom: 32px;}
  

  .master-header-info.gutter{padding-left: 8px; padding-right: 8px;}

  // app-price{flex-direction: column; justify-content: space-between; align-items: flex-start;}
  // app-price .price-old {justify-content: space-between; width: 100%;} 

  .square-wrap{padding-bottom: 100%;}
  .square-wrap-small{padding-bottom: 100% !important;}
  // .horizontal-wrap-half{padding-bottom: 75%;}
}
@import 'colors';

body{font-family:"futura-pt",sans-serif;}

h1, h2, h3, h4{margin:0;}

h1 {
	font-family:"futura-pt",sans-serif;
	font-weight: 700;
	font-size: 1.6rem;
}
@supports (font-size: clamp(1.6rem, 2vw, 2rem)) {
	h1 {
		font-size: clamp(1.6rem, 2vw, 2rem);
	}
}
h2 {
	font-family:"futura-pt",sans-serif;
	font-weight: 700;
	font-size: 1.4rem;

}
@supports (font-size: clamp(1.3rem, 2vw, 2rem)) {
	h2 {
		font-size: clamp(1.3rem, 2vw, 2rem);
	}
}
h3 {
	font-family:"futura-pt",sans-serif;
	font-weight: 700;
	font-size: 1.3rem;
}
@supports (font-size: clamp(1.2rem, 3.5vw, 1.5rem)) {
	h3 {
		font-size: clamp(1.2rem, 3.5vw, 1.5rem);
	}
}
h4 {
	font-weight: 700;
	font-size: 1rem;
}
@supports (font-size: clamp(1rem, 2.5vw, 1.1rem)) {
	h4 {
		font-size: clamp(1rem, 2.5vw, 1.1rem);
	}
}


p {
	font-family:"futura-pt",sans-serif;
	margin:0; padding:0; 
	line-height: 1.4rem;
	font-size: 1rem;
	font-weight: 400;
	/* 16px */
}

strong{font-weight: 700;}

p.lead{ font-size: 20px; line-height: 24px; color:$primary}
p.secondary{color:$secondary-accent}

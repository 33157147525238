$white: #ffffff;
$black: #000;
$primary: #c4b19d;
// $primary: #f0b454;
$accent: #eeb900;
$accent-break: #637aff;
$primary-font: #161616;
$secondary: #3E3B38;
$secondary-accent: #9f9698;
$tertiary: #f7f7fb;
$grey: #f3f3f3;
$pink: #f8cece;
$teal: #01aaad;
$yellow: #eeb900;
$green: #4caf50;
$orange: #fbad18;
$blue: #00aeef;
$red: #ea4c3a;
$light-red: #ffbbb4;
$light-bg: #f1eeeb;
$amber: #ffc107;
$lime: #cddc39;
$deep-purple: #673ab7;
$cyan: #00bcd4;
$deep-pink: #880e4f;
$light-green: #8bc34a;
$light-grey: #fcfcfc;
$dark-grey: #eaeaea;
$brown: #795548;
$blue-grey: #607d8b;

$gold: #D5bf82;
$rosegold: #ddb091;
$whitegold: #e0f5fa;
$blackgold: #141414;


$border-default: #e6e6e6;
$background: #ffffff;
$background-darker: #222221;
$border-darker: #2b2b2b;
$shadow-border: #000;
$shadow-grey: #f5f5f5;
$shadow-white-btn: #fff;
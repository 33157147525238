@use '@angular/material' as mat;
@include mat.core();
@import '@angular/material/theming';

.tk-futura-pt { font-family: "futura-pt",sans-serif; }
.tk-futura-pt-bold { font-family: "futura-pt-bold",sans-serif; }

$milionoczu-primary: (
    50: #151311,
    100: #151311,
    200: #151311,
    300: #151311,
    400: #151311,
    500: #151311,
    600: #151311,
    700: #151311,
    800: #151311,
    900: #151311,
    A100: #151311,
    A200: #151311,
    A400: #151311,
    A700: #151311,
    contrast: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    )
);

$milionoczu-secondary: (
    50: #151311,
    100: #151311,
    200: #151311,
    300: #151311,
    400: #151311,
    500: #151311,
    600: #151311,
    700: #151311,
    800: #151311,
    900: #151311,
    A100: #151311,
    A200: #151311,
    A400: #151311,
    A700: #151311,
    contrast: (
      50: #ffffff,
      100: #ffffff,
      200: #ffffff,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #ffffff,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    )
);

$my-primary: mat-palette($milionoczu-primary);
$my-accent: mat-palette($milionoczu-secondary);

$typography: mat.define-typography-config(
  $font-family: 'futura-pt',
);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 typography: $typography,
));

@import 'fonts';
@import 'colors';


$rounded-corners: 0;
$rounded-corners-buttons: 0;
$primary-gutter-double: 12vw;
$primary-gutter: 4vw;
$primary-gutter-v: 4vw;
$primary-gutter-v-half: 2vw;
$primary-gutter-half: 2vw;
$primary-gutter-fourth: 1.5vw;
$global-ease: cubic-bezier(0.19, 1, 0.19, 1);